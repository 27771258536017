var swipe = {

	init: function () {

		var swiperHero = new Swiper('.hero_slide', {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			lazy: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});

		// var galleryThumbsOne = new Swiper('.swiper-container.gallery_slide_thumbs.s3', {
		// 	// spaceBetween: 0,
		// 	slidesPerView: 6,
		// 	// freeMode: true,
		// 	watchSlidesVisibility: true,
		// 	watchSlidesProgress: true,
		// });			

		var swiperGalleryOne = new Swiper('.swiper-container.gallery_slide.s2', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});	
		var swiperGalleryTwo = new Swiper('.swiper-container.gallery_slide.s3', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});	
		var swiperGalleryThree = new Swiper('.swiper-container.gallery_slide.s5', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		
		var swiperGalleryThree = new Swiper('.swiper-container.gallery_slide.s7', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});	
		var swiperGalleryFour = new Swiper('.swiper-container.gallery_slide.t6', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});	
		var swiperGalleryFive = new Swiper('.swiper-container.gallery_slide.x200', {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			// thumbs: {
			// 	swiper: galleryThumbsOne
			// },
			// Default parameters for smallest screen
			slidesPerView: 1,
		});	
	}
};

var swipeResponsive = {

	init: function () {

		// breakpoint where swiper will be destroyed
		// and switches to a dual-column layout
		const breakpoint = window.matchMedia( '(min-width:1024px)' );
		// keep track of swiper instances to destroy later
		let mySwiperOne;
		let mySwiperTwo;
		let mySwiperThree;

		//////////////////////////////////////////////////////////////////
		const breakpointChecker = function() {
		   // if larger viewport and multi-row layout needed
		   if ( breakpoint.matches === false ) {
		      // clean up old instances and inline styles when available
		      if ( mySwiperOne !== undefined ) mySwiperOne.destroy( true, true );
		      if ( mySwiperTwo !== undefined ) mySwiperTwo.destroy( true, true );
		      if ( mySwiperThree !== undefined ) mySwiperThree.destroy( true, true );
		      // or/and do nothing
		      return;
		   // else if a small viewport and single column layout needed
		   } else if ( breakpoint.matches === true ) {
		      // fire small viewport version of swiper
		      return enableSwiper();
		   }
		};

		//////////////////////////////////////////////////////////////////
		const enableSwiper = function() {
		   mySwiperOne = new Swiper ('.modelo_slide_menu', {
				loop: true,
				autoplay: {
					delay: 5000,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				// Default parameters for smallest screen
				slidesPerView: 2,
				spaceBetween: 0,
				// Responsive breakpoints
				// breakpointsInverse: true,
				// breakpoints: {
				// // when window width is >= 1024px
				// 	1600: {
				// 	  slidesPerView: 3
				// 	  // spaceBetween: 20
				// 	}, 1024: {
				// 	  slidesPerView: 2
				// 	  // spaceBetween: 20
				// 	}
				// }			      
	   		});

	   		mySwiperTwo = new Swiper ('.slide_gallery', {
				loop: true,
				autoplay: {
					delay: 3500,
				},
				// navigation: {
				// 	nextEl: '.swiper-button-next',
				// 	prevEl: '.swiper-button-prev',
				// },
				// Default parameters for smallest screen
				slidesPerView: 3,
				spaceBetween: 0,
				// Responsive breakpoints
				// breakpointsInverse: true,
				breakpoints: {
				// when window width is >= 1024px
					1600: {
					  slidesPerView: 3
					  // spaceBetween: 20
					}, 1440: {
					  slidesPerView: 2
					  // spaceBetween: 20
					}
				}			      
	   		});

	   		mySwiperThree = new Swiper ('.slide_timeline', {
				loop: true,
				autoplay: {
					delay: 6500,
				},
				// navigation: {
				// 	nextEl: '.swiper-button-next',
				// 	prevEl: '.swiper-button-prev',
				// },
				// Default parameters for smallest screen
				slidesPerView: 1,
				spaceBetween: 0,
				// mousewheel: true,
				keyboard: {
					enabled: true,
					onlyInViewport: false,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},    
	   		});
		};

		// keep an eye on viewport size changes
		breakpoint.addListener(breakpointChecker);
		// kickstart
		breakpointChecker();		
	}
};

swipe.init();
swipeResponsive.init();